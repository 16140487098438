










































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class PasswordModal extends Vue {
  @Prop({ default: false }) show!: boolean;

  password = '';
  confirmPassword = '';
  loading = false;

  get showModal() {
    return this.show;
  }

  get disableSave() {
    return !this.passwordsMatch;
  }

  get passwordsMatch() {
    return (
      this.password === this.confirmPassword &&
      this.password.length + this.confirmPassword.length
    );
  }

  get newPassword() {
    return this.passwordsMatch ? this.confirmPassword : '';
  }

  handleClose() {
    this.password = '';
    this.confirmPassword = '';
    this.$emit('toggle');
  }

  handleSave() {
    if (this.newPassword) {
      this.loading = true;
      this.$store.dispatch('changePassword', this.newPassword).then(() => {
        this.handleClose();
        this.loading = false;
      });
    }
  }
}
